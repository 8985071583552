<template>
  <div class="container">
    <div class="go-view">
      <div class="row g-5">
        <div class="col-12 col-lg-6">

          <div class="mb-2">
            <h2>Resumo da Entrega</h2>
          </div>
          <div class="address-info">
            <div class="trip-info">
              <span class="address-review">{{ pickup }}{{ pickupComplemento }} | Data e Hora: {{ pickupDateFormatted
                }} -
                {{ pickupTime }}</span><br>➡️<br>
              <span class="address-review">{{ dropoff }} - {{ dropoffComplemento }}</span>
            </div>
          </div>

          <!-- Detalhes da Retirada -->
          <div class="recommendation">
            <h3>1. Detalhes da Retirada</h3>
            <div class="row g-1">
              <div class="col-12 col-lg-4">
                <div class="option">
                  <input type="radio" v-model="selectedPickupOption" id="pickupOption1" value="1 Ajudante" />
                  <label for="pickupOption1">
                    <img src="@/assets/user-1.png" alt="Opção 1" />
                    <div>
                      <p>1 Ajudante</p>
                      <h4>A forma mais comum de suporte</h4>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="option">
                  <input type="radio" v-model="selectedPickupOption" id="pickupOption2" value="2 Ajudantes" />
                  <label for="pickupOption2">
                    <img src="@/assets/user-2.png" alt="Opção 2" />
                    <div>
                      <p>2 Ajudantes</p>
                      <h4>Melhor experiência para cargas grandes</h4>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="option">
                  <input type="radio" v-model="selectedPickupOption" id="pickupOption3" value="Sem Ajudante" />
                  <label for="pickupOption3">
                    <img src="@/assets/no-user.png" alt="Opção 3" />
                    <div>
                      <p>Sem ajudante</p>
                      <h4>Forma econômica de entrega</h4>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <!-- Campos adicionais para Retirada -->
            <div v-if="selectedPickupOption === '1 Ajudante' || selectedPickupOption === '2 Ajudantes'"
              class="additional-options">
              <div class="mb-3">
                <div class="field">
                  <div class="row">
                    <div class="col-8">
                      <label for="pickupElevator">1.2 Elevador de serviço disponível?</label>
                    </div>
                    <div class="col-4 d-flex">
                      <input type="radio" id="pickupElevatorYes" :value="true" v-model="pickupHasElevator" />
                      <label for="pickupElevatorYes">Sim</label>
                      <input type="radio" id="pickupElevatorNo" :value="false" v-model="pickupHasElevator" />
                      <label for="pickupElevatorNo">Não</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3" v-if="pickupHasElevator === 'Não'">
                <div class="field">
                  <div class="mb-1">
                    <label for="pickupFloorSelect">1.3 Andar onde o item <strong>está:</strong></label>
                  </div>
                  <select id="pickupFloorSelect" v-model="selectedPickupFloor" class="form-select">
                    <option value="térreo">Térreo</option>
                    <option v-for="n in 42" :key="n" :value="n">{{ n }}º andar</option>
                  </select>
                </div>
              </div>
            </div>


            <hr />

            <!-- Detalhes da Entrega -->
            <div class="recommendation">
              <h3>2. Detalhes da Entrega</h3>
              <div class="row g-1">
                <div class="col-12 col-lg-4">
                  <div class="option">
                    <input type="radio" v-model="selectedDropoffOption" id="dropoffOption1" value="1 Ajudante" />
                    <label for="dropoffOption1">
                      <img src="@/assets/user-1.png" alt="Opção 1" />
                      <div>
                        <p>1 Ajudante</p>
                        <h4>A forma mais comum de suporte</h4>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="option">
                    <input type="radio" v-model="selectedDropoffOption" id="dropoffOption2" value="2 Ajudantes" />
                    <label for="dropoffOption2">
                      <img src="@/assets/user-2.png" alt="Opção 2" />
                      <div>
                        <p>2 Ajudantes</p>
                        <h4>Melhor experiência para cargas grandes</h4>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="option">
                    <input type="radio" v-model="selectedDropoffOption" id="dropoffOption3" value="Sem Ajudante" />
                    <label for="dropoffOption3">
                      <img src="@/assets/no-user.png" alt="Opção 3" />
                      <div>
                        <p>Sem ajudante</p>
                        <h4>Forma econômica de entrega</h4>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <!-- Campos adicionais para Entrega -->
            <div v-if="selectedDropoffOption === '1 Ajudante' || selectedDropoffOption === '2 Ajudantes'"
              class="additional-options">
              <div class="mb-3">
                <div class="field">
                  <div class="row d-flex">
                    <div class="col-8">
                      <label for="dropoffElevator">2.1 Elevador de serviço disponível?</label>
                    </div>
                    <div class="col-4 d-flex">
                      <input type="radio" id="dropoffElevatorYes" :value="true" v-model="dropoffHasElevator" />
                      <label for="dropoffElevatorYes">Sim</label>
                      <input type="radio" id="dropoffElevatorNo" :value="false" v-model="dropoffHasElevator" />
                      <label for="dropoffElevatorNo">Não</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3" v-if="dropoffHasElevator === 'Não'">
                <div class="field">
                  <div class="mb-1">
                    <label for="dropoffFloorSelect">2.2 Andar onde o item <strong>ficará:</strong></label>
                  </div>
                  <select id="dropoffFloorSelect" v-model="selectedDropoffFloor" class="form-select">
                    <option value="térreo">Térreo</option>
                    <option v-for="n in 42" :key="n" :value="n">{{ n }}º andar</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <!-- Input para upload de imagens -->
          <div class="mb-4">
            <label for="uploadImages">
              <h3>3. Envie imagens do item:</h3>
              <p>Envie uma imagem de cada objeto, isoladamente:</p>
            </label>
            <input type="file" id="uploadImages" multiple @change="handleImageUpload" />
          </div>

          <!-- Barra de progresso -->
          <div v-if="loading" class="progress-bar-container">
            <div class="progress-bar" :style="{ width: progress + '%' }"></div>
          </div>

          <!-- Spinner -->
          <div v-if="loading && progress < 100" class="spinner"></div>

          <!-- Pré-visualização das imagens -->
          <div class="row">
            <div class="image-preview-item col-4" v-for="(image, index) in uploadedImages" :key="index">
              <img :src="image.url" :alt="'Imagem ' + (index + 1)" />
              <button @click="removeImage(index)" class="remove-image-button">X</button>
              <div v-if="image.prediction" class="prediction-label">
                <span>{{ image.prediction }}</span>
              </div>
            </div>
          </div>
          <hr />



          <button class="cta-button" @click="openModal" v-if="!predicting">Pedir um Frete Leve</button>
          <div v-if="predicting" class="spinner"></div>

          <PaymentModal v-if="isModalOpen" :isVisible="isModalOpen" :tripValue="tripValue" :tripDetails="tripDetails"
            @close="closeModal" />


        </div>
        <div class="col-12 col-lg-6 d-flex align-items-center">
          <!-- Mapa -->
          <!-- Preloader -->
          <div v-if="isLoadingMap" class="preloader">
            <div class="spinner"></div>
            <p>Carregando mapa...</p>
          </div>
          <div class="map-container">
            <div id="map" class="map"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global google */

import { toRaw } from 'vue';
import PaymentModal from "@/components/PaymentModal.vue";
import * as mobilenet from "@tensorflow-models/mobilenet";
import * as tf from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgl";
import "@tensorflow/tfjs-backend-cpu";

export default {
  name: "GoPage",
  components: {
    PaymentModal,
  },
  props: ["pickup", "pickupComplemento", "pickupDate", "pickupTime", "dropoff", "dropoffComplemento", "pickupDateFormatted"],
  data() {
    return {
      map: null,
      directionsService: null,
      directionsRenderer: null,
      selectedPickupOption: "",
      selectedPickupFloor: "térreo",
      pickupHasElevator: "Não",
      selectedDropoffOption: "",
      selectedDropoffFloor: "térreo",
      dropoffHasElevator: "Não",
      uploadedImages: [],
      loading: false,
      predicting: false,
      progress: 0,
      isModalOpen: false,
      tripValue: 45.5,
      mobilenetModel: null,
      totalWeight: 0,
      distanceInKm: 0,
      isLoadingMap: true,
    };
  },
  methods: {
    async loadModel() {
      try {
        const backends = ["webgl", "cpu"];
        for (const backend of backends) {
          await tf.setBackend(backend);
          await tf.ready();
          // console.log("Backend atual:", tf.getBackend());
          break;
        }

        this.mobilenetModel = await mobilenet.load();
        // console.log("Modelo MobileNet carregado com sucesso.");
      } catch (error) {
        // console.error("Erro ao carregar o modelo MobileNet:", error);
      }
    },
    async predictImage(imageElement) {
      if (!this.mobilenetModel) {
        console.error("Modelo não carregado.");
        return;
      }

      try {
        const rawModel = toRaw(this.mobilenetModel);
        const predictions = await rawModel.classify(imageElement);
        const bestPrediction = predictions.reduce((prev, current) =>
          (prev.probability > current.probability) ? prev : current
        );

        this.uploadedImages = this.uploadedImages.map(img =>
          img.url === imageElement.src
            ? { ...img, prediction: bestPrediction.className || "Indefinido" }
            : img
        );

        const materialInfo = await this.getMaterialInfo(bestPrediction.className);
        if (materialInfo) {
          const objectInfo = await this.getObjectInfo(bestPrediction.className, materialInfo);
          if (objectInfo) {
            const { weight } = objectInfo;
            this.totalWeight += weight;
          }
        }
      } catch (error) {
        console.error("Erro ao classificar a imagem:", error);
      }
    },

    async getMaterialInfo(objectName) {
      try {
        const apiKey = process.env.VUE_APP_OPENAI_API_KEY;
        if (!apiKey) {
          // console.error("API Key do ChatGPT não foi encontrada.");
          return;
        }

        const response = await fetch("https://api.openai.com/v1/chat/completions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: "gpt-4",
            messages: [
              { role: "system", content: "Você é um assistente que responde apenas com o material provável." },
              { role: "user", content: `Para um objeto classificado como ${objectName}, qual o material mais comum e pesado que ele pode ter? Responda apenas com o material.` },
            ],
            max_tokens: 20,
          }),
        });

        const data = await response.json();
        return data.choices[0].message.content.trim();
      } catch (error) {
        console.error("Erro ao obter informações sobre o material do ChatGPT:", error);
      }
    },

    async getObjectInfo(objectName, material) {
      try {
        const apiKey = process.env.VUE_APP_OPENAI_API_KEY;
        if (!apiKey) {
          console.error("API Key do ChatGPT não foi encontrada.");
          return;
        }

        const response = await fetch("https://api.openai.com/v1/chat/completions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: "gpt-4",
            messages: [
              { role: "system", content: "Você é um assistente que responde apenas com dados numéricos." },
              { role: "user", content: `Qual é o peso médio e as dimensões em cm de um objeto classificado como ${objectName} feito de ${material}? Responda no formato: 'Peso: X kg, Dimensões: A cm x B cm' e sem texto adicional.` },
            ],
            max_tokens: 50,
          }),
        });

        const data = await response.json();
        if (!data || !data.choices || !data.choices[0] || !data.choices[0].message || !data.choices[0].message.content) {
          console.error("Resposta do ChatGPT não contém os dados esperados.");
          return null;
        }

        const result = data.choices[0].message.content;
        const weightMatch = result.match(/Peso:\s*(\d+(\.\d+)?)\s*kg/i);
        // const dimensionsMatch = result.match(/Dimensões:\s*(\d+(\.\d+)?)\s*cm\s*x\s*(\d+(\.\d+)?)\s*cm/i);

        const weight = weightMatch ? parseFloat(weightMatch[1]) : null;
        // const dimensions = dimensionsMatch ? `${dimensionsMatch[1]} cm x ${dimensionsMatch[3]} cm` : null;

        if (weight === null) {
          console.error("Não foram encontrados dados válidos de peso ou dimensões.");
          return null;
        }

        // console.log(`Peso sugerido: ${weight} kg`);
        // console.log(`Dimensões sugeridas: ${dimensions}`);

        return { weight };
      } catch (error) {
        console.error("Erro ao obter informações do ChatGPT:", error);
        return null;
      }
    },

    calculateTripValue() {
      const pricePerKm = parseFloat(process.env.VUE_APP_PRECO_POR_KM || 1.5);
      const pricePerKg = parseFloat(process.env.VUE_APP_PRECO_POR_KG || 0.5);
      const rushHourRate = parseFloat(process.env.VUE_APP_RUSH_HOUR || 1.1);
      const helperFee = parseFloat(process.env.VUE_APP_AJUDANTE || 25.0);
      const floorFee = parseFloat(process.env.VUE_APP_ANDAR || 2.0);
      const fixedTax = parseFloat(process.env.VUE_APP_TAXA_FIXA || 10);
      const commission = parseFloat(process.env.VUE_APP_COMISSAO || 1.2);

      let valorRetirada = 0;
      if (this.selectedPickupOption === "1 Ajudante") {
        valorRetirada += helperFee * (1 + this.totalWeight / 1000);
        // console.log(`Valor Retirada com 1 Ajudante: ${valorRetirada}`);

        const pickupFloor = this.selectedPickupFloor === 'térreo' ? 1 : parseInt(this.selectedPickupFloor, 10);
        if (pickupFloor > 1 && this.pickupHasElevator === "Não") {
          const additionalRetirada = pickupFloor * floorFee * (1 + this.totalWeight / 500);
          valorRetirada += additionalRetirada;
          // console.log(`Valor adicional de Retirada por andar: ${additionalRetirada}`);
        }
      } else if (this.selectedPickupOption === "2 Ajudantes") {
        valorRetirada += 2 * helperFee * (1 + this.totalWeight / 1000);
        // console.log(`Valor Retirada com 2 Ajudantes: ${valorRetirada}`);

        const pickupFloor = this.selectedPickupFloor === 'térreo' ? 1 : parseInt(this.selectedPickupFloor, 10);
        if (pickupFloor > 1 && this.pickupHasElevator === "Não") {
          const additionalRetirada = pickupFloor * floorFee * (1 + this.totalWeight / 500);
          valorRetirada += additionalRetirada;
          // console.log(`Valor adicional de Retirada por andar: ${additionalRetirada}`);
        }
      }

      // console.log(`Total Valor Retirada: ${valorRetirada}`);

      let valorEntrega = 0;
      if (this.selectedDropoffOption === "1 Ajudante") {
        valorEntrega += helperFee * (1 + this.totalWeight / 1000);
        // console.log(`Valor Entrega com 1 Ajudante: ${valorEntrega}`);

        const dropoffFloor = this.selectedDropoffFloor === 'térreo' ? 1 : parseInt(this.selectedDropoffFloor, 10);
        if (dropoffFloor > 1 && this.dropoffHasElevator === "Não") {
          const additionalEntrega = dropoffFloor * floorFee * (1 + this.totalWeight / 500);
          valorEntrega += additionalEntrega;
          // console.log(`Valor adicional de Entrega por andar: ${additionalEntrega}`);
        }
      } else if (this.selectedDropoffOption === "2 Ajudantes") {
        valorEntrega += 2 * helperFee * (1 + this.totalWeight / 1000);
        // console.log(`Valor Entrega com 2 Ajudantes: ${valorEntrega}`);

        const dropoffFloor = this.dropoffHasElevator === "Sim"
          ? 1
          : (this.selectedDropoffFloor === 'térreo' ? 1 : parseInt(this.selectedDropoffFloor, 10));

        if (dropoffFloor > 1 && this.dropoffHasElevator === "Não") {
          const additionalEntrega = dropoffFloor * floorFee * (1 + this.totalWeight / 500);
          valorEntrega += additionalEntrega;
          // console.log(`Valor adicional de Entrega por andar: ${additionalEntrega}`);
        }
      }

      // console.log(`Total Valor Entrega: ${valorEntrega}`);

      const valorDistancia = this.distanceInKm * pricePerKm || 0;
      const valorPeso = this.totalWeight * pricePerKg;

      // console.log(`Valor Distância (Km * Preço por Km): ${valorDistancia}`);
      // console.log(`Valor Peso (Kg * Preço por Kg): ${valorPeso}`);

      const pickupTimeHours = parseInt(this.pickupTime.split(":")[0], 10);
      const isRushHour = (pickupTimeHours >= 11 && pickupTimeHours <= 13) || (pickupTimeHours >= 17 && pickupTimeHours <= 19);
      const rushMultiplier = isRushHour ? rushHourRate : 1;

      // console.log(`Horário de Pico: ${isRushHour ? 'Sim' : 'Não'}, Multiplicador de Hora de Pico: ${rushMultiplier}`);

      this.tripValue = (((valorRetirada + valorEntrega) * rushMultiplier) + valorDistancia + valorPeso + fixedTax) * commission;

      // console.log(`Trip Value calculado: ${this.tripValue}`);
    },

    handleImageUpload(event) {
      const files = Array.from(event.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const image = new Image();
          image.src = e.target.result;

          image.onload = () => {
            this.uploadedImages.push({
              file,
              url: e.target.result,
            });
            this.predictImage(image);
          };
        };
        reader.readAsDataURL(file);
      });
    },
    removeImage(index) {
      this.uploadedImages.splice(index, 1);
    },
    async openModal() {
  this.predicting = true;
  await this.calculateTotalWeight();
  this.calculateTripValue();
  
  // Prepara os dados da viagem com tipagem correta
  this.tripDetails = {
    pickupLocation: this.pickup,
    pickupComplement: this.pickupComplemento,
    pickupDate: this.pickupDate,
    pickupTime: this.pickupTime,
    pickupOption: this.selectedPickupOption,
    pickupFloor: this.selectedPickupFloor === 'térreo' ? 0 : parseInt(this.selectedPickupFloor, 10),
    pickupElevatorAvailable: this.pickupHasElevator === 'Sim', // Converte para booleano
    dropoffLocation: this.dropoff,
    dropoffComplement: this.dropoffComplemento,
    dropoffOption: this.selectedDropoffOption,
    dropoffFloor: this.selectedDropoffFloor === 'térreo' ? 0 : parseInt(this.selectedDropoffFloor, 10),
    dropoffElevatorAvailable: this.dropoffHasElevator === 'Sim', // Converte para booleano
    totalWeight: this.totalWeight,
    tripDistance: this.distanceInKm,
    tripValue: this.tripValue,
    status: 'viagem solicitada'
  };
  
  this.predicting = false;
  this.isModalOpen = true;
},
    async calculateTotalWeight() {
      try {
        // Reinicia o peso total antes de calcular
        this.totalWeight = 0;

        // Itera sobre as imagens e usa `predictImage` para estimar o peso de cada uma
        for (const image of this.uploadedImages) {
          const img = new Image();
          img.src = image.url;
          await this.predictImage(img); // Aguarda a previsão de cada imagem
        }
      } catch (error) {
        console.error("Erro ao calcular o peso total:", error);
      }
    },


    closeModal() {
      this.isModalOpen = false;
    },
    loadGoogleMaps() {
      const self = this;
      this.isLoadingMap = true;

      window.initMap = function () {
        self.initializeMap();
        self.initializeRoute();
      };

      if (typeof google === 'undefined') {
        const apiKeyGoogle = process.env.VUE_APP_GOOGLE_MAPS_API;
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKeyGoogle}&libraries=places&callback=initMap`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      } else {
        this.initializeMap();
        this.initializeRoute();
      }
    },
    initializeMap() {
      if (typeof google === 'undefined') return;

      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: -22.9068, lng: -43.1729 },
        zoom: 13,
      });
      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer();
      this.directionsRenderer.setMap(this.map);
    },

    initializeRoute() {
      if (!this.pickup || !this.dropoff || typeof google === 'undefined') return;

      const request = {
        origin: this.pickup,
        destination: this.dropoff,
        travelMode: google.maps.TravelMode.DRIVING,
      };

      this.directionsService.route(request, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.directionsRenderer.setDirections(result);
          this.isLoadingMap = false;

          const distanceInMeters = result.routes[0].legs[0].distance.value;
          this.distanceInKm = (distanceInMeters / 1000).toFixed(2);
          // console.log(`Distância: ${this.distanceInKm} km`);

          const pricePerKm = parseFloat(process.env.VUE_APP_PRECO_POR_KM || 1.5);
          this.valorDistancia = this.distanceInKm * pricePerKm;
          // console.log(`Valor Distância (Km * Preço por Km): ${this.valorDistancia}`);

          this.calculateTripValue();
        } else {
          alert("Não foi possível calcular a rota");
          this.isLoadingMap = false;
        }
      });
    },
  },
  watch: {
    selectedPickupOption: "calculateTripValue",
    selectedPickupFloor: "calculateTripValue",
    pickupHasElevator: "calculateTripValue",
    selectedDropoffOption: "calculateTripValue",
    selectedDropoffFloor: "calculateTripValue",
    dropoffHasElevator: "calculateTripValue",
    totalWeight: "calculateTripValue",
    distanceInKm: "calculateTripValue",
    pickupTime: "calculateTripValue",
  },
  async mounted() {
    await this.loadModel();
    console.log("Dados recebidos:", {
      pickup: this.pickup,
      pickupComplemento: this.pickupComplemento,
      pickupDate: this.pickupDate,
      pickupTime: this.pickupTime,
      dropoff: this.dropoff,
      dropoffComplemento: this.dropoffComplemento,
      pickupDateFormatted: this.pickupDateFormatted,
    });
    this.loadGoogleMaps();
  },
};
</script>


<style scoped>
.container {
  max-width: 1280px;
  margin: 0px auto;
  width: 100%;
}

.go-view {
  padding: 20px 0px
}

.left-panel {
  width: 100%;
  padding-right: 60px;
  overflow-y: auto;
  max-height: 85vh;
}

.address-info {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
}

label {
  margin-right: 10px;
}

.recommendation h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.trip-info {
  border: solid 1px #efefef;
  border-radius: 10px;
  padding: 15px;
}

.option {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  min-height: 160px;
}

.option img {
  width: 30px;
  height: auto;
  margin-right: 15px;
}

.option.selected {
  border-color: #000;
}

.cta-button {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  background-color: #FF6038;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.map-container {
  width: 100%;
}

.map {
  width: 100%;
  height: 90vh;
  border-radius: 10px;
}

input[type="radio"] {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

input[type="file"] {
  font-size: 14px;
  width: 80vw;
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.image-preview-item {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.image-preview-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Barra de Progresso */
.progress-bar-container {
  position: relative;
  background-color: #ddd;
  border-radius: 5px;
  height: 10px;
  margin-top: 10px;
  width: 100%;
}

.progress-bar {
  background-color: #FF6038;
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s;
}

/* Spinner */
.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ddd;
  border-top-color: #FF6038;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 10px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Pré-visualização das imagens */
.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.image-preview-item {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.image-preview-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* estilo do modal */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.payment-button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #FF6038;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #ccc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.address-review {
  font-size: 14px;
}

.preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  height: 100vh;
  width: 100vw;
}

.preloader .spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ddd;
  border-top-color: #FF6038;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .left-panel {
    width: 100%;
    padding-right: 0px;
    max-height: none;
  }

  .option {
    min-height: 120px;
  }

  .map {
    height: 50vh;
  }

  .container {
    max-width: 90vw;
  }
}
</style>
