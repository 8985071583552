<template>
  <div v-if="isVisible" class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <h2>Detalhes do Pagamento</h2>
      <div class="mb-3">
        <p>Valor da Corrida: <strong>R$ {{ tripValue.toFixed(2) }}</strong> </p>
      </div>

      <p class="subtitle">Preencha os dados abaixo para finalizar o pedido e <strong>criar sua conta:</strong></p>

      <!-- Formulário para capturar os dados do usuário -->
      <div class="form-group">
        <div class="row g-1">
          <div class="col-12 col-lg-6"><input type="text" class="form-control" v-model="name"
              placeholder="Nome Completo" required /></div>
          <div class="col-12 col-lg-6"> <input type="email" class="form-control" v-model="email" placeholder="Email"
              required /></div>
          <div class="col-12 col-lg-6">
            <input type="tel" class="form-control" v-model="phone" placeholder="Telefone" @input="applyPhoneMask"
              required />
          </div>
          <div class="col-12 col-lg-6" style="position: relative;">
            <input :type="showPassword ? 'text' : 'password'" class="form-control" v-model="password"
              placeholder="Senha" required />
            <button type="button" class="show-password-btn" @click="togglePasswordVisibility">
              {{ showPassword ? 'Ocultar' : 'Mostrar' }}
            </button>
          </div>
        </div>



      </div>

      <button class="payment-button" @click="makePayment">Pagar Com Stripe</button>
      <button class="close-button" @click="close">Fechar</button>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: "PaymentModal",
  props: {
    isVisible: { type: Boolean, required: true },
    tripValue: { type: Number, required: true },
    tripDetails: { type: Object, required: true },
    predicting: { type: Boolean, required: true }
  },
  data() {
    return {
      stripe: null,
      name: "",
      email: "",
      phone: "",
      password: "",
      showPassword: false
    };
  },
  async mounted() {
    // console.log("Stripe Public Key:", process.env.VUE_APP_STRIPE_PUBLIC); Verifique se a chave é exibida
    if (!process.env.VUE_APP_STRIPE_PUBLIC) {
      console.error("Chave pública do Stripe ausente ou incorreta.");
      return;
    }
    // this.stripe = await loadStripe('pk_test_51HFVICFVjDlaAAiiGSMOkK58goMKjvb9DrLhTUOjjEShxE7ihaT0J290ipJ3kXlDwBkvot5UxnoTp4WqUENKSjAU006raVCXpX');
    this.stripe = await loadStripe('pk_live_51HFVICFVjDlaAAiiCf2JYXoGPuOhDtTuBQ5WB4FSZIXmFoMsSPxxB6mHccGkXxyvBR497Q1NpXYtjFN0tzpjoo0G00hdJWlOAy');
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async makePayment() {
      try {
        console.log("Dados da viagem que serão enviados:", {
          tripDetails: {
            name: this.name,
            email: this.email,
            phone: this.phone,
            pickupLocation: this.tripDetails.pickupLocation,
            pickupComplement: this.tripDetails.pickupComplement,
            pickupDate: this.tripDetails.pickupDate,
            pickupTime: this.tripDetails.pickupTime,
            dropoffLocation: this.tripDetails.dropoffLocation,
            dropoffComplement: this.tripDetails.dropoffComplement,
            pickupFloor: this.tripDetails.pickupFloor,
            pickupElevatorAvailable: !!this.tripDetails.pickupElevatorAvailable,
            dropoffFloor: this.tripDetails.dropoffFloor,
            dropoffElevatorAvailable: !!this.tripDetails.dropoffElevatorAvailable,
            pickupOption: this.tripDetails.pickupOption,
            dropoffOption: this.tripDetails.dropoffOption,
            tripValue: this.tripDetails.tripValue,
            tripDistance: this.tripDetails.tripDistance,
            totalWeight: this.tripDetails.totalWeight,
          }
        });

        const response = await fetch('/api/create-customer-and-checkout', {
        // const response = await fetch('http://localhost:3000/trips/create-customer-and-checkout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            phone: this.phone,
            password: this.password,
            tripDetails: {
              pickupLocation: this.tripDetails.pickupLocation,
              pickupComplement: this.tripDetails.pickupComplement,
              pickupDate: this.tripDetails.pickupDate,
              pickupTime: this.tripDetails.pickupTime,
              dropoffLocation: this.tripDetails.dropoffLocation,
              dropoffComplement: this.tripDetails.dropoffComplement,
              pickupFloor: this.tripDetails.pickupFloor || 0,
              pickupElevatorAvailable: !!this.tripDetails.pickupElevatorAvailable,
              dropoffFloor: this.tripDetails.dropoffFloor || 0,
              dropoffElevatorAvailable: !!this.tripDetails.dropoffElevatorAvailable,
              pickupOption: this.tripDetails.pickupOption,
              dropoffOption: this.tripDetails.dropoffOption,
              tripValue: this.tripDetails.tripValue,
              tripDistance: this.tripDetails.tripDistance,
              totalWeight: this.tripDetails.totalWeight,
            }
          }),
        });

        const data = await response.json();

        if (data.error) {
          console.error("Erro na criação da sessão de pagamento:", data.error);
          return;
        }

        if (!data.sessionId) {
          throw new Error("sessionId não foi retornado da API.");
        }

        const { error } = await this.stripe.redirectToCheckout({ sessionId: data.sessionId });
        if (error) {
          console.error("Erro no redirecionamento para o checkout do Stripe:", error);
        }
      } catch (error) {
        console.error("Erro ao iniciar o pagamento:", error);
      }
    },
    applyPhoneMask() {
      let rawPhone = this.phone.replace(/\D/g, ""); // Remove tudo que não for dígito
      if (rawPhone.length <= 2) {
        this.phone = `(${rawPhone}`;
      } else if (rawPhone.length <= 7) {
        this.phone = `(${rawPhone.slice(0, 2)}) ${rawPhone.slice(2)}`;
      } else if (rawPhone.length <= 11) {
        this.phone = `(${rawPhone.slice(0, 2)}) ${rawPhone.slice(2, 3)} ${rawPhone.slice(3, 7)}-${rawPhone.slice(7)}`;
      } else {
        this.phone = `(${rawPhone.slice(0, 2)}) ${rawPhone.slice(2, 3)} ${rawPhone.slice(3, 7)}-${rawPhone.slice(7, 11)}`;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Adiciona estilos para o preloader */
.preloader {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

.modal-content {
  background: white;
  padding: 35px;
  border-radius: 8px;
  width: 600px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.payment-button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #FF6038;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #ccc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

p.subtitle {
  font-size: 14px;
  text-align: left;
  margin: 10px 0px
}

.show-password-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
}
</style>
