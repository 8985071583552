<template>
    <nav class="navbar">
        <div class="container">
            <div class="navbar-left">
                <!-- Logo da Frete Leve -->
                <router-link to="/">
                    <img src="../assets/logo-h.png" alt="Frete Leve Logo" class="logo" />
                </router-link>

                <!-- Links de navegação -->
                <!-- <router-link to="/" class="nav-link">Viajar</router-link>
                <router-link to="/drive" class="nav-link">Dirigir</router-link>
                <router-link to="/business" class="nav-link">Empresa</router-link> -->

                <!-- Dropdown para 'Sobre' -->
                <!-- <div class="dropdown">
                    <router-link to="/about" class="nav-link">Sobre</router-link>
                    <div class="dropdown-content">
                        <router-link to="/about/history">Nossa História</router-link>
                        <router-link to="/about/investors">Investidores</router-link>
                        <router-link to="/about/careers">Trabalhe Conosco</router-link>
                    </div>
                </div> -->
            </div>

            <div class="navbar-right">
                <!-- Idioma -->
                <!-- <span class="nav-link">PT-BR</span> -->

                <!-- Link de Ajuda -->
                <router-link to="/cadastro-agente" class="nav-link">Quero Ser Agente de Carga</router-link>
                <router-link to="/painel" class="user-dropdown-content">Minha Conta</router-link>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "NavBar",
};
</script>

<style scoped>
/* Estilos principais da barra de navegação */
.navbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #fff;
    font-family: Arial, sans-serif;
}

.navbar-left,
.navbar-right {
    display: flex;
    align-items: center;
}

.logo {
    width: 150px;
    margin-right: 20px;
}

.nav-link {
    margin: 0 10px;
    text-decoration: none;
    color: black;
}

.nav-link:hover {
    text-decoration: underline;
}

.dropdown,
.user-dropdown {
    position: relative;
}

.dropdown-content,
.user-dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    min-width: 100px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown:hover .dropdown-content,
.user-dropdown:hover .user-dropdown-content {
    display: block;
}

.dropdown-content router-link,
.user-dropdown-content router-link {
    display: block;
    padding: 8px 12px;
    color: black;
    text-decoration: none;
}

.dropdown-content router-link:hover,
.user-dropdown-content router-link:hover {
    background-color: #f1f1f1;
}

.user-name {
    padding: 5px 10px;
    background-color: #FF6038;
    color: white;
    border-radius: 20px;
}

.container {
    margin: 0px auto;
    max-width: 1280px;
}
</style>